import * as React from "react";
import { useQueryParam, StringParam } from "use-query-params";

import SuperHeader from "../components/superHeader";
import Header from './../components/header';
import InnerLayout from '../components/innerLayout';
import WhyCreator from "../components/whyCreator";
import Content from '../modules/comparison/content';
import EarlyAccess from "../components/earlyAccess";
import Footer from './../components/footer';
import useComposeUrl from "../hooks/useComposeUrl";

const formatComparisonParam = (v) => {
  return ['social-media', 'creator-monetization'].includes(v) ? v : 'social-media';
};

const Comparison = ({ location }) => {
  const currentPageUrl = useComposeUrl(location.pathname);
  const [comparisonParam] = useQueryParam("compare-with", StringParam);

  return (
    <>
      <SuperHeader title="Comparison / Starbeat" currentUrl={currentPageUrl} />
      <Header />
      <InnerLayout>
        <WhyCreator />
        <Content comparisonParam={formatComparisonParam(comparisonParam)} />
        <EarlyAccess />
      </InnerLayout>
      <Footer />
    </>
  );
}

export default Comparison;
