import React, { useEffect, useState } from 'react';

import LogoDark from '../../images/logo-dark.svg';
import InstagramLogo from '../../images/logos/instagram-logo.svg';
import ChingariLogo from '../../images/logos/chingari-logo.png';
import YoutubeLogo from '../../images/logos/youtube-logo.svg';
import PepulLogo from '../../images/logos/pepul-logo.svg';
import TwitterLogo from '../../images/logos/twitter-logo.svg';
import ShareChatTakatakLogo from '../../images/logos/share-chat-mx-takatak-logo.png';
import Logo from '../../images/logo.svg';
import PatreonLogo from '../../images/logos/patreon-logo.png';
import QoohooLogo from '../../images/logos/qoohoo-logo.png';
import TagmangoLogo from '../../images/logos/tagmango-logo.svg';
import SubscribeStarLogo from '../../images/logos/subscribe-star-logo.png';
import CameoLogo from '../../images/logos/cameo-logo.png';
import FanstanLogo from '../../images/logos/fanstan.webp';

const Content = ({ comparisonParam }) => {
  const [selectedTab, setSelectedTab] = useState(comparisonParam);

  useEffect(() => {
    setSelectedTab(comparisonParam);
  }, [comparisonParam]);

  return (
    <section className="py-12 bg-white dark:bg-gray-900 sm:py-16 lg:py-20">
      <div className="px-6 mx-auto max-w-7xl sm:px-12 lg:px-16 xl:px-20">
        <div className="max-w-xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
            Starbeat vs. the World
          </h2>
          <p className="mt-4 text-base font-medium text-gray-500 dark:text-primary-100">
            The good of social media and the freedom to Monetization.
          </p>
        </div>

        <div className="mt-12">
          <div className="flex items-center justify-center">
            <nav
              className="inline-flex justify-center p-1 border border-gray-200 rounded-full bg-primary-100 dark:bg-gray-700 dark:text-gray-40 dark:border-gray-700">
              <button
                className={`px-6 py-3 font-medium text-center text-gray-500 transition-all duration-200 rounded-full ${selectedTab === 'social-media' ? 'active bg-secondary-500 !text-white !font-semibold' : ''}`}
                onClick={() => setSelectedTab('social-media')}
              >
                Social Media
              </button>

              <button
                className={`px-6 py-3 font-medium text-center text-gray-500 transition-all duration-200 rounded-full ${selectedTab === 'creator-monetization' ? 'active bg-secondary-500 !text-white !font-semibold' : ''}`}
                onClick={() => setSelectedTab('creator-monetization')}
                >
                Creator Monetization
              </button>
            </nav>
          </div>

          {
            selectedTab === 'social-media' && (
              <div>
                <div className="pt-12 lg:block">
                  <div className="flex flex-col">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden">
                          <table className="w-full table-auto lg:table-fixed">
                            <thead>
                              <tr>
                                <th className="py-8 pr-6" width="24%">

                                </th>

                                <th className="px-6 py-8 text-center bg-primary-500 rounded-t-xl" width="16%">
                                  <img className="w-auto h-8 mx-auto" src={LogoDark} alt="Starbeat Logo"/>
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-8 mx-auto" src={InstagramLogo}
                                    alt="Starbeat Instagram Page"/>
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-12 mx-auto" src={ChingariLogo}
                                    alt="Starbeat Chingari Page"/>
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-12 mx-auto" src={YoutubeLogo}
                                    alt="Starbeat Youtube Page"/>
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-12 mx-auto" src={PepulLogo}
                                    alt="Starbeat Pepul Page"/>
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-6 mx-auto" src={TwitterLogo}
                                    alt="Starbeat Twitter Page"/>
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-12 mx-auto filter mix-blend-multiply"
                                    src={ShareChatTakatakLogo} alt="Starbeat ShareChat TakaTak Page"/>
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Creator-Fan Interaction
                                </td>

                                <td
                                  className="px-6 py-4 text-center text-white border-b bg-primary-500 border-white/20 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Fan-Fan Interactions
                                </td>

                                <td
                                  className="px-6 py-4 text-center text-white border-b dark:border-purple-800 bg-primary-500 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Consumers Pay for Content
                                </td>

                                <td
                                  className="px-6 py-4 text-center text-white border-b dark:border-purple-800 bg-primary-500 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Flexible Subscriptions
                                </td>

                                <td
                                  className="px-6 py-4 text-center text-white border-b dark:border-purple-800 bg-primary-500 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Sell Tickets/Merchandise
                                </td>

                                <td
                                  className="px-6 py-4 text-center text-white border-b dark:border-purple-800 bg-primary-500 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  NFTs & Web 3.0
                                </td>

                                <td className="px-6 py-4 text-center text-white border-b bg-primary-500 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  No Advertisements
                                </td>

                                <td
                                  className="px-6 py-4 text-center text-white border-b dark:border-purple-800 bg-primary-500 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Digital Rights & Privacy
                                </td>

                                <td
                                  className="px-6 py-4 text-center text-white border-b dark:border-purple-800 bg-primary-500 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Fan Recognition
                                </td>

                                <td
                                  className="px-6 py-4 text-center text-white border-b dark:border-purple-800 bg-primary-500 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Live Video Events
                                </td>

                                <td
                                  className="px-6 py-4 text-center text-white border-b dark:border-purple-800 bg-primary-500 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-6 pr-4">

                                </td>

                                <td className="px-4 py-6 text-center text-white bg-secondary-500 rounded-b-xl">
                                  <a href="#early-access" title="Join Starbeat" className="inline-flex items-center font-semibold text-white">
                                    Join Starbeat
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                      fill="currentColor">
                                      <path fillRule="evenodd"
                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                        clipRule="evenodd"></path>
                                    </svg>
                                  </a>
                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          {
            selectedTab === 'creator-monetization' && (
              <div>
                <div className="pt-12 lg:block">
                  <div className="flex flex-col">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden">
                          <table className="w-full table-auto lg:table-fixed">
                            <thead>
                              <tr>
                                <th className="py-8 pr-6" width="24%">

                                </th>

                                <th className="px-6 py-8 text-center bg-primary-100 rounded-t-xl" width="16%">
                                  <img className="object-contain w-auto h-8 mx-auto" src={Logo} alt="Starbeat Logo" />
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-16 mx-auto" src={PatreonLogo}
                                    alt="Patreon"/>
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-16 mx-auto" src={QoohooLogo}
                                    alt="Qoohoo"/>
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-16 mx-auto" src={TagmangoLogo}
                                    alt="Tagmango"/>
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-12 mx-auto"
                                    src={SubscribeStarLogo} alt="SubscribeStar"/>
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-6 mx-auto" src={CameoLogo} alt="CameoLogo"/>
                                </th>

                                <th className="px-6 py-8 text-center">
                                  <img className="object-contain w-auto h-12 mx-auto" src={FanstanLogo} alt="FanstanLogo"/>
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Creator-Fan Interaction
                                </td>

                                <td
                                  className="px-6 py-4 text-center border-b bg-primary-100 border-white/20 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Fan-Fan Interactions
                                </td>

                                <td
                                  className="px-6 py-4 text-center border-b dark:border-purple-800 bg-primary-100 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Consumers Pay for Content
                                </td>

                                <td
                                  className="px-6 py-4 text-center border-b dark:border-purple-800 bg-primary-100 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Flexible Subscriptions
                                </td>

                                <td
                                  className="px-6 py-4 text-center border-b dark:border-purple-800 bg-primary-100 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Sell Tickets/Merchandise
                                </td>

                                <td
                                  className="px-6 py-4 text-center border-b dark:border-purple-800 bg-primary-100 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  NFTs & Web 3.0
                                </td>

                                <td className="px-6 py-4 text-center border-b bg-primary-100 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  No Advertisements
                                </td>

                                <td
                                  className="px-6 py-4 text-center border-b dark:border-purple-800 bg-primary-100 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Digital Rights & Privacy
                                </td>

                                <td
                                  className="px-6 py-4 text-center border-b dark:border-purple-800 bg-primary-100 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Fan Recognition
                                </td>

                                <td
                                  className="px-6 py-4 text-center border-b dark:border-purple-800 bg-primary-100 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-4 pr-6 font-medium border-b border-gray-200 dark:border-purple-800">
                                  Live Video Events
                                </td>

                                <td
                                  className="px-6 py-4 text-center border-b dark:border-purple-800 bg-primary-100 border-white/20">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                </td>

                                <td className="px-6 py-4 text-center border-b border-gray-200 dark:border-purple-800">
                                  <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4"></path>
                                  </svg>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-6 pr-4">

                                </td>

                                <td className="px-4 py-6 text-center text-white bg-secondary-500 rounded-b-xl">
                                  <a href="#early-access" title="Join Starbeat" className="inline-flex items-center font-semibold text-white">
                                    Join Starbeat
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                      fill="currentColor">
                                      <path fillRule="evenodd"
                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                        clipRule="evenodd"></path>
                                    </svg>
                                  </a>
                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>

                                <td className="px-4 py-6 text-center">

                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </section>
  );
};

export default Content;
