import React from 'react';

const WhyCreator = () => (
  <section className="bg-white dark:bg-gray-900">
    <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="relative overflow-hidden rounded-3xl bg-secondary-100 dark:bg-secondary-900">
        <div className="relative px-6 py-12 sm:px-12 sm:py-16 lg:px-16 lg:py-20 xl:px-20">
          <div className="max-w-3xl mx-auto text-center">
            <p className="text-sm font-bold tracking-widest uppercase text-secondary-500 dark:text-secondary-400">
              Why be a starbeat creator?
            </p>
            <h1
              className="mt-6 text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl lg:text-5xl sm:tracking-tight">
              Flexibility & Freedom
            </h1>
            <p className="mt-6 text-lg font-medium text-gray-500 dark:text-gray-200 lg:text-xl">
              We strive to build a social media ecosystem that&apos;s inclusive, transparent and where authentic content is fairly rewarded.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default WhyCreator;
